import React from "react"
import { StaticQuery, graphql } from "gatsby"

import ContentPage from "../views/page"
const context = {
  locale: 'de'
}
const IndexPage = () => (
  <StaticQuery
    query={graphql`
    query SiteQuery {
      strapiPage(navigation: {path: {eq: "/"}}) {
        Titel
        TitelEn
        Inhalte {
          id
          anchor
          Text1
          Text1En
          Text2
          Text2En
          Text3
          Text3En
          Ausrichtung
          Ausrichtung1
          Ausrichtung2
          Ausrichtung3
          Akkordioneintraege{
            Text
            TextEn
            Titel
            TitelEn
          }
          Bildoverlay{
            Text
            TextEn
            Titel
            TitelEn
            Icon {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Icontitel
            IcontitelEn
            CallToAction {
              anchor
              CallToAction
              CallToActionEn
              CallToActionLinkIntern{
                navigation {
                  id
                  path
                }
              }
              CallToActionLinkExtern
              Bild {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      height: 46
                    )
                  }
                }
              }
            }
          }
          Carouseleintraege{
            Text
            TextEn
            Titel
            TitelEn
            Bild {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Icon {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Icontitel
            IcontitelEn
            CallToAction {
              anchor
              CallToAction
              CallToActionEn
              CallToActionLinkIntern{
                navigation {
                  id
                  path
                }
              }
              CallToActionLinkExtern
              Bild {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      height: 46
                    )
                  }
                }
              }
            }
          }
          ansprechpartner {
            Beschreibung
            Bild {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                  )
                }
              }
            }
            Email
            Name
            Telefon
            Vorname
          }
          Email
          Buttons {
            Titel
            TitelEn
            page {
              navigation {
                path
              }
            }
          }
          strapi_component
          CallToAction
          CallToActionEn
          CallToActionLink {
            id
            Titel
            TitelEn
            navigation {
              id
            }
          }
          CallToActionLinkExtern
          Linkbild {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 46
                )
              }
            }
          }
          CallToActionButtons {
            anchor
            CallToAction
            CallToActionEn
            CallToActionLinkExtern
            CallToActionLinkIntern {
              navigation {
                id
                path
              }
            }
            Bild {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    height:46
                  )
                }
              }
            }
          }
          Einleitung
          EinleitungEn
          Positionen {
            Eintrag
            EintragEn
          }
          Zitate {
            Name
            NameEn
            Zitat
            ZitatEn
            Bild {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    height: 110
                  )
                }
              }
            }
          }
          Variante
          Icons {
            anchor
            Titel
            TitelEn
            Einleitung
            EinleitungEn
            CallToAction
            CallToActionEn
            CallToActionLink {
              id
              Titel
              TitelEn
              navigation {
                id
                path
              }
            }
            Icon {
              localFile {
                publicURL
              }
            }
          }
          Bildausrichtung
          Bild {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                )
              }
            }
          }
          Vollbild {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  layout: FULL_WIDTH
                )
              }
            }
          }
          VollbildTablet {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  layout: FULL_WIDTH
                )
              }
            }
          }
          VollbildMobile {
            localFile {
              childImageSharp {
                gatsbyImageData (
                  layout: FULL_WIDTH
                )
              }
            }
          }
          Hintergrundbild {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData (
                  layout: FULL_WIDTH
                )
              }
            }
          }
          Hintergrundvideo {
            localFile {
              publicURL
            }
          }
          Text
          TextEn
          TitelEn
          Titel
          Unterseite
          kategorie {
            id
          }
          URL,
          width,
          height,
          Video {
            localFile {
              publicURL
            }
          }
          VideoEn {
            localFile {
              publicURL
            }
          }
          Hintergrund
          Videokomponente {
            Titel
            TitelEn
            Hintergrund
            Video {
              localFile {
                publicURL
              }
            }
            VideoEn {
              localFile {
                publicURL
              }
            }
          }
        }
        description
        keywords
        keywordsEn
        navigation{
          path
        }
      }
      allStrapiNeuigkeiten(
        sort: {
         fields: [Sticky, Datum], 
         order: [DESC, DESC]
       }) {
       nodes {
         Einleitung
         EinleitungEn
         Slug
         Titel
         TitelEn
         link_intern{
            navigation {
              id
              path
            }
         }
         kategorie {
           id
           Button
           ButtonEn
         }
         Bilder {
           localFile {
             childImageSharp {
               gatsbyImageData(
                 layout: CONSTRAINED
                 width: 500
               )
             }
           }
         }
       }
     }
      allStrapiDownloadKategorie {
        nodes {
          Titel
          TitelEn
          downloads {
            Text
            TextEn
            Titel
            TitelEn
            Datei {
              ext
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      allStrapiPressespiegel(sort: {
        fields: [Datum], 
        order: [DESC, DESC]
      }) {
        nodes {
          Datei {
            ext
            localFile {
              publicURL
            }
          }
          Datum(formatString: "DD.MM.YYYY")
          Medium
          Link
          Titel
          TitelEn
        }
      }
      allStrapiWebsiteTextbaustein {
        nodes {
          Text
          TextEn
          Titel
        }
      }
      allStrapiNavigation {
        nodes {
          items {
            id
            slug
            path
            related {
              Titel
              TitelEn
              hideInMenu
              showInFooter
            }
          }
          path
          strapiId
          related {
            Titel
            TitelEn
            hideInMenu
            showInFooter
          }
          slug
        }
      }
    }
  `}
    render={data => (
      <ContentPage data={data} pageContext={context}></ContentPage>
    )}
  />
)

export default IndexPage
